import { semanticSearch } from '@api/semanticSearch';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAppSelector } from '@redux/hooks';
import { compose } from '@xo-union/react-css-modules';
import { Button } from '@xo-union/tk-component-buttons';
import { Field, Form } from '@xo-union/tk-component-fields';
import { IconButton } from '@xo-union/tk-component-icons';
import { useAtomValue, useSetAtom } from 'jotai';
import React, { FC, useCallback, useRef } from 'react';
import AnalyticsEvents from '../../../../../../constants/analytics';
import {
	mockRequestBody,
	useMockPredictApi,
} from '../_mock_api_delete_later/mock-api';
import {
	isSemanticSearchCalledAtom,
	resetSemanticSearchResponseAtom,
	semanticSearchResponseAtom,
	updateIsFetchingAtom,
} from '../atoms';
import { hydrateVendors } from '../utils';
import { SemanticControls } from './SemanticControls';
import Styles from './SemanticSearch.styles.scss';

const fieldClasses = compose({
	container: Styles.fieldContainer,
	input: Styles.fieldInput,
});

export interface SemanticSearchProps {
	fieldValue: string;
	setFieldValue: (value: string) => void;
}

export const SemanticSearch: FC<SemanticSearchProps> = (props) => {
	const { fieldValue, setFieldValue } = props;
	const marketCode = useAppSelector((state) => state.search.marketCode);
	const setSemanticSearchResponse = useSetAtom(semanticSearchResponseAtom);
	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);
	const setIsFetching = useSetAtom(updateIsFetchingAtom);
	const resetSemanticSearchResponse = useSetAtom(
		resetSemanticSearchResponseAtom,
	);
	const closeButtonRef = useRef<HTMLButtonElement>(null);

	useMockPredictApi();

	const { track } = useAnalyticsContext();
	const trackSearchButtonClicked = useCallback(() => {
		const properties = {
			product: 'marketplace',
			searchTerm: fieldValue,
			sourcePage: isSemanticSearchCalled
				? 'semantic_results'
				: 'category results',
			sourceContent: 'free_search_box',
			action: 'search_completed',
		};

		track({
			event: AnalyticsEvents.VENDOR_SEARCH_INTERACTION,
			properties,
		});
	}, [fieldValue, isSemanticSearchCalled, track]);

	const handleFieldClick = () => {
		if (
			document.activeElement === closeButtonRef?.current &&
			fieldValue.length > 0
		) {
			setFieldValue('');
			resetSemanticSearchResponse();
		}
	};

	const handleSearch = async () => {
		trackSearchButtonClicked();
		setIsFetching(true);
		try {
			const semanticSearchResponse = await semanticSearch({
				style_description: fieldValue,
				market_code: marketCode,
				...mockRequestBody,
			});
			const ids = semanticSearchResponse?.storefronts?.map((s) => s.id);
			const vendors = await hydrateVendors(ids);
			setSemanticSearchResponse({
				isFetching: false,
				isSemanticSearchCalled: true,
				response_id: semanticSearchResponse?.response_id,
				searchFieldValue: fieldValue,
				storefronts: vendors,
				storefrontsCount: vendors.length,
			});
		} catch (error) {
			setIsFetching(false);
			// Will need to implement Honeybadger error logging when integrating with real API
			// Will need to update the accompaying test for this error handling
			// biome-ignore lint/suspicious/noConsoleLog: Mock API logging
			console.log('error: ', error);
		}
	};

	return (
		<div className={Styles.semanticSearchWrapper}>
			<SemanticControls />

			<Form className={Styles.semanticSearchForm}>
				<Field
					classes={fieldClasses}
					name="search..."
					onChange={(e) => setFieldValue(e.target.value)}
					onKeyDown={(e) => {
						const iconButtonFocused =
							document.activeElement === closeButtonRef?.current;
						if (e.key === 'Enter' && !iconButtonFocused) {
							handleSearch();
						}
					}}
					value={fieldValue}
				/>
				<IconButton<HTMLButtonElement>
					aria-label="clear search field"
					className={
						fieldValue.length > 0
							? Styles.clearButton
							: Styles.clearButtonHidden
					}
					name="close_circle"
					onClick={handleFieldClick}
					ref={closeButtonRef}
					size="md"
					tabIndex={fieldValue.length > 0 ? 0 : -1}
				/>
				<Button
					className={Styles.semanticSearchButton}
					color="primary"
					iconName="search"
					onClick={handleSearch}
					size="lg"
					type="submit"
					aria-label="Submit search"
				/>
			</Form>
		</div>
	);
};
