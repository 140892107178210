import VendorResult from '@components/vendorResult/VendorResult';
import { quickResponderAssignmentSelector } from '@redux/experiments/selectors/quick-responder';
import { useAppSelector } from '@redux/hooks';
import { Column, ColumnProps } from '@xo-union/tk-component-grid';
import classNames from 'classnames';
import React, { useContext, useMemo, VFC } from 'react';
import WeddingsContext from '../../../../../../contexts/WeddingsContext/WeddingsContext';
import styles from './styles.scss';

export interface ResultProps {
	impressionType: VendorImpressions.ImpressionType;
	index: number;
	isSupplemental?: boolean;
	vendor: Vendor.Raw;
	vendorsLength?: number;
}

const Result: VFC<ResultProps> = (props) => {
	const { vendor, index, impressionType, isSupplemental, vendorsLength } =
		props;
	const { onSaveHandler, getIsSaved, getMaxResultColumns } =
		useContext(WeddingsContext);

	let columnSize: ColumnProps = { xs: '12', md: '6', xxl: '4' };
	if (getMaxResultColumns() === 4) {
		columnSize = { xs: '12', md: '6', lg: '4', xxl: '3' };
	}

	const shouldShowDivider = useMemo(
		() => (vendorsLength ? index !== vendorsLength - 1 : false),
		[index, vendorsLength],
	);

	const isQuickResponder = Boolean(vendor?.vendorBehavior?.quickResponder);
	const isInQuickResponderAssignment: boolean =
		useAppSelector((state) => quickResponderAssignmentSelector(state)) ===
		'quick-responder';

	return (
		<Column
			{...columnSize}
			className={classNames({
				[styles.newVendorCardColumn]: shouldShowDivider,
				[styles['card-column']]: true,
			})}
		>
			<div
				className={classNames({
					[styles.card]: shouldShowDivider,
					[styles['no-gutter-column']]: true,
					[styles['quick-responder-badge']]: isInQuickResponderAssignment,
					[styles['divider-quick-responder']]:
						isQuickResponder && isInQuickResponderAssignment,
				})}
			>
				<VendorResult
					impressionType={impressionType}
					index={index}
					isSaved={getIsSaved(vendor.id)}
					isSupplemental={isSupplemental}
					onSaveHandler={onSaveHandler}
					rawVendor={vendor}
				/>
			</div>
			{shouldShowDivider && (
				<div className={styles['divider-wrapper']} role="presentation">
					<div className={styles.divider} />
				</div>
			)}
		</Column>
	);
};

export default Result;
