import { semanticSearchApiUrl } from '@settings';
import { useEffect } from 'react';
import { mockResponse } from './mock-data';

export const mockRequestBody = {
	included_vendor_tiers: 'paid-only',
	exclude_storefronts_without_image: true,
	exclude_unclaimed_venues: true,
	limit: 10,
};

export const useMockPredictApi = () => {
	const originalFetch = window.fetch;

	useEffect(() => {
		window.fetch = async (url, options) => {
			if (url === semanticSearchApiUrl) {
				// biome-ignore lint/suspicious/noConsoleLog: Mock API logging
				console.log(
					'Searched with a request body of: ',
					JSON.parse((options as { body: string }).body),
				);
				return new Response(JSON.stringify(mockResponse), {
					status: 200,
					headers: { 'Content-Type': 'application/json' },
				});
			}

			return originalFetch(url, options);
		};
	}, [originalFetch]);
};
