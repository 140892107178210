import { useClickOutside } from '@hooks/use-click-outside';
import { useSearchOnDropdownSelection } from '@hooks/use-search-on-dropdown-selection';
import { useAppSelector } from '@redux/hooks';
import Icon from '@xo-union/tk-component-icons';
import React, {
	FC,
	RefObject,
	createRef,
	useEffect,
	useRef,
	useState,
} from 'react';
import { SemanticDropdown } from './SemanticDropdown';
import Styles from './styles.scss';

const handleArrowKey = (
	key: string,
	selectedOptionIdx: number,
	maxIndex: number,
) => {
	if (key === 'ArrowDown') {
		return selectedOptionIdx < maxIndex ? selectedOptionIdx + 1 : 0;
	}
	return selectedOptionIdx > 0 ? selectedOptionIdx - 1 : maxIndex;
};

const useOptionRefs = (size: number) => {
	const refs = useRef<RefObject<HTMLButtonElement>[]>([]);
	if (refs.current.length !== size) {
		refs.current = Array(size)
			.fill(null)
			.map((_, i) => refs.current[i] || createRef<HTMLButtonElement>());
	}
	return refs.current;
};

export interface SemanticCategoryProps {
	setIsModalOpen: (value: boolean) => void;
}

export const SemanticCategory: FC<SemanticCategoryProps> = (props) => {
	const { setIsModalOpen } = props;
	const categories = useAppSelector((state) => state.categories);
	const isMobile = useAppSelector((state) => state.viewport.isMobile);
	const [selectedOptionIdx, setSelectedOptionIdx] = useState(0);
	const [dropDownOpen, setDropDownOpen] = useState(false);
	const { onDropdownSelection } = useSearchOnDropdownSelection(setDropDownOpen);
	const categoryRef = useRef<HTMLDivElement | null>(null);
	const optionRefs = useOptionRefs(categories.length);

	useClickOutside(categoryRef, () => {
		setDropDownOpen(false);
	});

	const handleOnOpen = () => {
		if (isMobile) {
			return setIsModalOpen(true);
		}
	};

	const handleCategoryFieldClick = () => {
		return isMobile ? handleOnOpen() : setDropDownOpen(!dropDownOpen);
	};

	const handleKeyDown = (event: React.KeyboardEvent, catCode?: string) => {
		const maxIndex = categories.length - 1;
		let newIndex = selectedOptionIdx;
		const { key } = event;

		if (key === 'Enter') {
			catCode
				? onDropdownSelection(catCode, categories)
				: setDropDownOpen(!dropDownOpen);
		}

		if (key === 'ArrowDown' || key === 'ArrowUp') {
			newIndex = handleArrowKey(key, selectedOptionIdx, maxIndex);
		}

		setSelectedOptionIdx(newIndex);
		event.preventDefault();
	};

	useEffect(() => {
		const currentRef = optionRefs[selectedOptionIdx];

		currentRef?.current?.focus();
		if (selectedOptionIdx > 0) {
			currentRef?.current?.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			});
		}
	}, [selectedOptionIdx, optionRefs[selectedOptionIdx]]);

	return (
		<div className={Styles.semanticContainer} ref={categoryRef}>
			<button
				className={Styles.categoryButton}
				onClick={handleCategoryFieldClick}
				onKeyDown={handleKeyDown}
				type="button"
			>
				<div className={Styles.semanticLocationText}>Reception Venues</div>
				<Icon name={'caret_down'} size="sm" />
			</button>
			{dropDownOpen && (
				<SemanticDropdown
					categories={categories}
					handleKeyDown={handleKeyDown}
					onDropdownSelection={onDropdownSelection}
					optionRefs={optionRefs}
					selectedOptionIdx={selectedOptionIdx}
				/>
			)}
		</div>
	);
};
