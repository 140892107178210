import FooterCategories from '@components/footer_categories';
import Breadcrumbs from '@components/shared/Breadcrumbs';
import { useIsPricingEnabled } from '@feature-flags/hooks/VendorsSearch/use-is-pricing-enabled';
import { useListingsFeatures } from '@feature-flags/hooks/VendorsSearch/use-listings-features';
import { mobileMapViewAssignmentSelector } from '@redux/experiments/selectors/mobile-map-view';
import { semanticSearchAssignmentSelector } from '@redux/experiments/selectors/semantic-search';
import { getConversations as getConversationsThunk } from '@redux/messagedVendors/thunks';
import { setIsReferred as setIsReferredAction } from '@redux/settings';
import { requestAnonymousId } from '@redux/settings/actions';
import { CategoryCode } from '@typings/Category';
import { CategoryFilters } from '@typings/filters';
import { AppliedFilters } from '@typings/filters';
import { SORT_DIRECTIONS } from '@utils/sortParam';
import { compose as composeCSS } from '@xo-union/react-css-modules';
import { Row, TopLevelContainer } from '@xo-union/tk-component-grid';
import { CookieStorage } from 'cookie-storage';
import isEqual from 'lodash/isEqual';
import queryString from 'query-string';
import React, { FC, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { SortType } from 'types/search';
import { XO_SESSION_TOKEN } from '../../../../constants/membership';
import { updateCategoryFilter } from '../actions/filters';
import { changeLocation } from '../actions/location';
import { fetchSearchResults, updatePage, updateSort } from '../actions/search';
import DynamicFAQ from '../components/DynamicFAQ';
import PageDescription from '../components/PageDescription';
import { VenueFacetLinks } from '../components/VenueFacetLinks';
import * as utils from '../utils';
import { getValidFilters } from '../utils';
import FilterPills from './FilterPills';
import { SemanticSearchResults } from './SemanticSearch/components/SemanticSearchResults';
import TopBar from './TopBar';
import Styles from './WrappedVendorsSearch.scss';

const isMapViewSelected = (state: Redux.State) =>
	mobileMapViewAssignmentSelector(state) === 'map-toolbar';

const getSessionToken = () => {
	const cookieStorage = new CookieStorage();
	return cookieStorage.getItem(XO_SESSION_TOKEN);
};

interface Location {
	hash: string;
	pathname: string;
	search: string;
	state?: string;
}

interface Params {
	categorySlug: string;
	facet?: string;
	locationSlug: string;
}

type StateProps = ReturnType<typeof mapStateToProps>;

interface DispatchProps {
	changeLocation: (location: { city: string; stateCode: string }) => void;
	fetchSearchResults: (opts: unknown) => void;
	getConversations: (memberId: string, sessionToken: string) => void;
	isReferred: (value: boolean) => void;
	requestAnonymousId: () => void;
	updatePage: (page: number) => void;
	updateCategoryFilter: (filters: CategoryFilters) => void;
	updateSort: (sort: SortType | string) => void;
}

type WrappedVendorsSearchProps = StateProps & DispatchProps;

interface Previous {
	appliedFilters: AppliedFilters;
	categoryCode: CategoryCode;
	city: string;
	location: Location;
	marketCode: string;
	stateCode: string;
}

function usePrevious(value: Previous) {
	const ref = useRef<Previous>();
	useEffect(() => {
		ref.current = value;
	}, [value]);
	return ref.current;
}

const WrappedVendorsSearch: FC<WrappedVendorsSearchProps> = (props) => {
	const {
		appliedFilters,
		categories,
		categoryCode,
		categoryGuid,
		changeLocation,
		city,
		fetchSearchResults,
		getConversations,
		isInSemanticSearch,
		isReferred,
		marketCode,
		member,
		page,
		searchFilterMap,
		showMapView,
		stateCode,
		updateCategoryFilter,
		updatePage,
		updateSort,
		vendors,
	} = props;

	const location: Location = useLocation();
	const params: Params = useParams();

	useEffect(() => {
		requestAnonymousId();
		isReferred(true);
		if (member?.id) {
			getConversations(member.id, getSessionToken() || '');
		}
	}, [getConversations, isReferred, member]);

	const prevProps = usePrevious({
		appliedFilters,
		categoryCode,
		city: city || '',
		location,
		marketCode: marketCode || '',
		stateCode: stateCode || '',
	});

	useEffect(() => {
		if (prevProps) {
			const locationChanged =
				!isEqual(location, prevProps.location) ||
				city !== prevProps.city ||
				stateCode !== prevProps.stateCode ||
				marketCode !== prevProps.marketCode;
			const categoryChanged = categoryCode !== prevProps.categoryCode;
			const appliedFiltersChanged = !isEqual(
				appliedFilters,
				prevProps.appliedFilters,
			);
			const searchChanged = location.search !== prevProps.location.search;
			const update =
				searchChanged ||
				appliedFiltersChanged ||
				locationChanged ||
				categoryChanged;

			if (update) {
				syncPage();
			}
		}
	}, [
		appliedFilters,
		categoryCode,
		city,
		location,
		marketCode,
		prevProps,
		stateCode,
	]);

	useEffect(() => {
		if (member?.id) {
			getConversations(member.id, getSessionToken() || '');
		}
	}, [getConversations, member]);

	const getCategoryFilters = (filterArr: unknown) => {
		const initialCategories = utils.getEmptyFilter(categories);

		return utils.buildCategoryFilters(
			filterArr,
			searchFilterMap,
			initialCategories,
		);
	};

	const buildCategoryFilers = () => {
		const {
			page = 1,
			sort,
			gatekeeper,
			...restFilters
		} = queryString.parse(location.search);
		const filterArr = utils.splitMultipleFilters(restFilters);

		if (params?.facet) {
			return {
				filters: utils.getAllFilterIds(appliedFilters),
				categoryFilters: {
					...appliedFilters,
				},
				page,
				sort,
				gatekeeper,
			};
		}

		const filters = getValidFilters(filterArr, searchFilterMap);
		const categoryFilters = getCategoryFilters(filterArr);

		return {
			filters,
			categoryFilters,
			page,
			sort,
			gatekeeper,
		};
	};

	/**
	 * On url change we will
	 * 1. re-fetch search results
	 * 2. update location, pagination & filter panel
	 *
	 * if we see a facet we will remember the previous filters
	 * else we will derive our filters only from reading the url
	 *
	 */
	const syncPage = () => {
		const userLocation = utils.getLocationFromSlug(params.locationSlug);
		const { categoryFilters, filters, page, sort } = buildCategoryFilers();

		syncPageCount(Number(page));
		syncFilters(categoryFilters);
		syncLocation(userLocation);
		if (typeof sort === 'string') {
			syncSort(sort);
		}

		fetchSearchResults({
			categoryCode,
			categoryGuid,
			filters: [categoryGuid, ...filters],
			location: userLocation,
			page: Number(page),
			sort,
		});
	};

	const syncFilters = (categoryFilters: CategoryFilters) => {
		return updateCategoryFilter(categoryFilters);
	};

	const syncLocation = (location: { city: string; stateCode: string }) => {
		if (location.city !== city || location.stateCode !== stateCode) {
			changeLocation(location);
		}
	};

	const syncPageCount = (newPage: number) => {
		if (page !== newPage) {
			updatePage(newPage);
		}
	};

	const syncSort = (sort: SortType | string) => {
		return updateSort(sort || SORT_DIRECTIONS.recommended.type);
	};

	useListingsFeatures();

	const isPricingEnabled = useIsPricingEnabled();

	return (
		<TopLevelContainer
			classes={composeCSS({
				'top-level-container': Styles.vendorSearchContainer, // Look to deprecate this, as it is not suggested to use it
			})}
		>
			<TopBar />
			<FilterPills
				match={{
					params: {
						facet: params.facet || '',
						locationSlug: params.locationSlug,
					},
				}}
				searchLocation={location.search}
				showMapView={showMapView}
				vendors={vendors}
			/>
			{isPricingEnabled && <div>Pricing is enabled</div>}
			<SemanticSearchResults isInSemanticSearch={isInSemanticSearch} />
			<VenueFacetLinks />
			<FooterCategories city={city || ''} stateCode={stateCode || ''} />
			<Row>
				<div className={Styles.faqAndPageWrapper} />
			</Row>
			<DynamicFAQ />
			<PageDescription />
			<Breadcrumbs
				className={Styles.footerBreadcrumb}
				categoryCode={categoryCode}
				city={city}
				stateCode={stateCode}
			/>
		</TopLevelContainer>
	);
};

export function mapStateToProps(state: Redux.State) {
	const {
		category: { id, code },
		filters: { categories, appliedFilters },
		location: { city, stateCode },
		search: {
			filters,
			pagination: { page },
			vendors,
		},
		settings: { marketCode },
	} = state;

	return {
		appliedFilters,
		categories,
		categoryGuid: id,
		categoryCode: code,
		city,
		marketCode,
		member: state.membership.member,
		page,
		searchFilterMap: utils.memoizedSearchFiltersToMap(filters.filters),
		stateCode,
		showMapView: isMapViewSelected(state),
		vendors,
		isInSemanticSearch:
			semanticSearchAssignmentSelector(state) === 'semantic-search',
	};
}

export const mapDispatchToProps = {
	changeLocation,
	fetchSearchResults,
	getConversations: getConversationsThunk,
	isReferred: setIsReferredAction,
	requestAnonymousId,
	updatePage,
	updateCategoryFilter,
	updateSort,
};

const enhance = compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
);

export default enhance(WrappedVendorsSearch);
