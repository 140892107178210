import { semanticSearchStorefronts } from '@api/storefronts';

export const hydrateVendors = async (
	vendorIds?: string[],
): Promise<Vendor.Raw[]> => {
	if (!vendorIds?.length) return [];

	const vendors = await semanticSearchStorefronts({ ids: vendorIds });

	return vendors?.data?.storefronts?.profiles || [];
};
