import * as truncate from '../../../utils/truncate';

export const hashString = (str: string) => {
	// src: https://werxltd.com/wp/2010/05/13/javascript-implementation-of-javas-string-hashcode-method/
	let hash = 0;

	for (let i = 0; i < str.length; i += 1) {
		const char = str.charCodeAt(i);
		// eslint-disable-next-line
		hash = (hash << 5) - hash + char;
		// eslint-disable-next-line
		hash = hash & hash; // Convert to 32bit integer
	}

	return hash.toString();
};

export const subtext = {
	'4b5e2d17-4963-4f8c-b358-f27b7cd357be': 'Top-reviewed local vendors',
	'53bf6340-d0eb-4211-9b0a-9723c6323201': 'Top Rated Vendors of the Year',
	'e632bfa7-71ce-4d22-9208-44ab16e0ded8': 'Top Rated Vendors of the Year',
	'19f33d4a-0a5a-48e2-af70-39f94ea4ea7f': 'Won Best of Weddings 4+ times',
};

export const truncateHeader = (
	defaultText: string,
	element: HTMLElement | null,
): Search.HeaderText => {
	if (!element) {
		return {
			firstLine: defaultText,
		};
	}

	const elementWidth = truncate.getElementWidth(element);
	const elementFont = truncate.getElementFont(element);
	const textWidth = truncate.getTextWidth(defaultText, elementFont);

	if (elementWidth && textWidth > elementWidth * 0.9) {
		const halfway = Math.ceil(defaultText.length / 2);
		const spaceAfterHalf = defaultText.slice(halfway).indexOf(' ');
		return {
			firstLine: defaultText.slice(0, halfway + spaceAfterHalf),
			secondLine: defaultText.slice(halfway + spaceAfterHalf),
		};
	}

	return {
		firstLine: defaultText,
	};
};

export const labels = {
	'Best Of Weddings': 'Award Winners',
	Settings: 'Venue Type',
	'Wedding Venue Amenities': 'Venue Amenities',
	'Starting Price Range': 'Starting Price',
	'Wedding Activities': 'Wedding Events',
	'Photo & Video': 'Photo & Video Options',
	Dresses: 'Dress Types',
	Planning: 'Planning Services',
	Beauty: 'Beauty Services',
	Fitness: 'Fitness Services',
	'Business Attributes': 'Support Diversity',
	'Price Range': 'Price',
};

type Category = keyof typeof labels;

export const getLabel = (
	category: Category,
): Category | Category[keyof Category] => labels[category] || category;

export default {
	labels,
	getLabel,
	subtext,
	truncateHeader,
};
