import { useAppSelector } from '@redux/hooks';
import { Column } from '@xo-union/tk-component-grid';
import React, { FunctionComponent, useContext, useState } from 'react';
import ClientSideRenderer from '../../../../../../components/client_side_renderer';
import WeddingsContext from '../../../../../../contexts/WeddingsContext/WeddingsContext';
import { getBuzzBadgeConfig } from '../utils/buzzBadges';
import BuzzBadge from './components/BuzzBadge';
import BuzzBadgeHeader from './components/BuzzBadgeHeader';
import Styles from './styles.scss';
import { renderStyles } from './utils';

interface BuzzBadgeSectionProps {
	categoryCode: string;
	currentPage: number;
	hasMoreSpotlights: boolean;
	marketCode?: string;
	sectionIndex: number;
}

const BuzzBadgeSection: FunctionComponent<BuzzBadgeSectionProps> = (props) => {
	const {
		categoryCode,
		currentPage,
		hasMoreSpotlights,
		marketCode,
		sectionIndex,
	} = props;

	const [buzzBadgeIds, setBuzzBadgeIds] = useState<string[]>([]);
	const { getMaxResultColumns } = useContext(WeddingsContext);

	const { count, offset } = getBuzzBadgeConfig(
		sectionIndex,
		getMaxResultColumns,
		hasMoreSpotlights,
	);

	return (
		<Column xs="12" className={buzzBadgeIds.length > 0 ? '' : Styles.hidden}>
			<ClientSideRenderer>
				<div className={renderStyles(buzzBadgeIds)}>
					<BuzzBadgeHeader buzzBadgeIds={buzzBadgeIds} />
					<div className={Styles.buzzBadgesContainer}>
						{Array.from({ length: count }, (_, idx) => (
							<BuzzBadge
								categoryCode={categoryCode}
								currentPage={currentPage}
								index={idx}
								key={`buzz-badge-${idx}`}
								marketCode={marketCode}
								offset={offset}
								setBuzzBadgeIds={setBuzzBadgeIds}
							/>
						))}
					</div>
				</div>
			</ClientSideRenderer>
		</Column>
	);
};

export default BuzzBadgeSection;
