import { compose as cssCompose } from '@xo-union/react-css-modules';
import { Column, Row } from '@xo-union/tk-component-grid';
import React, { Fragment, VFC } from 'react';
import { connect } from 'react-redux';
import Result from '../Result';
import SupplementalHeader from '../SupplementalHeader';
import Styles from '../styles.scss';

export interface SupplementalResultsProps {
	supplemental: Vendor.Raw[];
}

export const SupplementalResults: VFC<SupplementalResultsProps> = (props) => {
	const { supplemental } = props;

	return (
		<Fragment>
			<Row>
				<Column xs="12">
					<SupplementalHeader />
				</Column>
			</Row>
			<Row vGutters classes={cssCompose({ row: Styles.rowContainer })}>
				{supplemental.map((vendor, i) => (
					<Result
						key={vendor.id}
						impressionType="Rec Module: Directory - SupplementalResults"
						index={i}
						vendor={vendor}
						isSupplemental
					/>
				))}
			</Row>
		</Fragment>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	supplemental: state.search.supplemental,
});

export default connect(mapStateToProps)(SupplementalResults);
