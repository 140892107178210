import { GooglePublisherTag } from '@components/google-publisher-tag';
import React, { Dispatch, FC, SetStateAction } from 'react';
import Styles from '../../styles.scss';

export interface Props {
	setIsAdEmpty: Dispatch<SetStateAction<boolean>>;
	targeting: GooglePublisherTagTKWW.Targeting;
}

export const SpotlightPromotion: FC<Props> = (props: Props) => {
	const { setIsAdEmpty, targeting } = props;

	return (
		<>
			<span className={Styles.spotlightPromotionLabel}>Promotion</span>
			<div className={Styles.spotlightContainer}>
				<GooglePublisherTag
					id="spotlight-ad"
					setIsAdEmpty={setIsAdEmpty}
					size={[943, 76]}
					targeting={targeting}
				/>
			</div>
		</>
	);
};

export default SpotlightPromotion;
