import { Body1, H4 } from '@xo-union/tk-ui-typography';
import React, { FunctionComponent } from 'react';
import Styles from './styles.scss';

export interface Props {
	hasSupplemental: boolean;
}

const ZeroResults: FunctionComponent<Props> = (props) => {
	const { hasSupplemental } = props;
	const sub = hasSupplemental ? ', or browse more vendors below' : '';

	return (
		<div className={Styles.zeroResults}>
			<H4>No Results Found</H4>
			<Body1>
				{`We're having trouble finding vendors that match. Please update your filters and search again${sub}.`}
			</Body1>
		</div>
	);
};

export default ZeroResults;
