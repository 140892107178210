import { Bone, Frame } from '@components/shared/Skeleton';
import { Row } from '@xo-union/tk-component-grid';
import cx from 'classnames';
import React, { FC, useState } from 'react';
import { adSlotEnv } from '../../../../../../../settings';
import SpotlightPromotion from './components/SpotlightPromotion';
import Styles from './styles.scss';

interface Props {
	categoryCode: string;
	isBOW: boolean;
	marketCode: string;
	className?: string;
}

export const SpotlightAd: FC<Props> = (props: Props) => {
	const { categoryCode, isBOW, marketCode } = props;
	const [isAdEmpty, setIsAdEmpty] = useState(true);

	const targeting: GooglePublisherTagTKWW.Targeting = {
		pos: 'local_spotlight_banner',
		city: marketCode,
		cat: categoryCode,
		env: adSlotEnv,
	};

	const className = props.className || '';

	if (isBOW) {
		targeting.bow = 'current_winners';
	}

	return (
		<Row className={cx(className)}>
			{isAdEmpty && (
				<Frame ariaLabel="Loading Ad..." className={Styles.skeletonFrame}>
					<Bone className={Styles.skeletonBone} freeze />
				</Frame>
			)}

			<SpotlightPromotion targeting={targeting} setIsAdEmpty={setIsAdEmpty} />
		</Row>
	);
};

export default SpotlightAd;
