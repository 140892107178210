import { semanticSearchAssignmentSelector } from '@redux/experiments/selectors/semantic-search';
import {
	selectIsBOWFilterApplied,
	selectShouldHideSpotlight,
} from '@redux/filters/selectors';
import { Column, Row } from '@xo-union/tk-component-grid';
import React, { Fragment, VFC } from 'react';
import { connect } from 'react-redux';
import ExitEarlyAlert from '../../../../components/auto-account-creation/exitEarlyAlert/ExitEarlyAlert';
import Breadcrumbs from '../../../../components/shared/Breadcrumbs';
import ResultsHeader from '../ResultsHeader';
import SpotlightAd from '../SearchResults/components/SpotlightAd';
import { SemanticTopBar } from '../SemanticSearch/components/SemanticTopBar';
import Styles from './styles.scss';

interface TopBarProps {
	categoryCode: Category.CategoryCode;
	isBOW: boolean;
	isMobile: boolean;
	inSemanticSearch: boolean;
	location: Redux.Location;
	marketCode: string;
	shouldHideSpotlight: boolean;
}

const TopBar: VFC<TopBarProps> = (props) => {
	const {
		categoryCode,
		isBOW,
		isMobile,
		inSemanticSearch,
		marketCode,
		shouldHideSpotlight,
		location: { city, stateCode },
	} = props;

	const shouldShowSpotlight = !shouldHideSpotlight && !isMobile;

	if (inSemanticSearch) {
		return (
			<SemanticTopBar
				categoryCode={categoryCode}
				isBOW={isBOW}
				location={props.location}
				marketCode={marketCode}
				shouldShowSpotlight={shouldShowSpotlight}
			/>
		);
	}

	return (
		<Fragment>
			{shouldShowSpotlight && (
				<Row>
					<Column xs="12">
						<SpotlightAd
							marketCode={marketCode}
							categoryCode={categoryCode}
							isBOW={isBOW}
							className={Styles.adBanner}
						/>
					</Column>
				</Row>
			)}
			<ExitEarlyAlert />
			{!isMobile && (
				<Row>
					<Column xs="12">
						<Breadcrumbs
							categoryCode={categoryCode}
							city={city}
							stateCode={stateCode}
							isHeader
						/>
					</Column>
				</Row>
			)}
			<ResultsHeader inSemanticSearch={inSemanticSearch} />
		</Fragment>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	categoryCode: state.category.code,
	isBOW: selectIsBOWFilterApplied(state),
	isMobile: state.viewport.isMobile,
	inSemanticSearch:
		semanticSearchAssignmentSelector(state) === 'semantic-search',
	location: state.location,
	marketCode: state.settings.marketCode,
	shouldHideSpotlight: selectShouldHideSpotlight(state),
});

export default connect(mapStateToProps)(TopBar);
