import SelectOptionStyles from '@components/shared/Search/Category/styles.scss';
import { Categories } from '@typings/redux';
import type { IconName } from '@xo-union/dist-iconography';
import Icon from '@xo-union/tk-component-icons';
import React, { FC } from 'react';
import Styles from './styles.scss';

export interface SemanticDropdownProps {
	categories: Categories;
	handleKeyDown: (event: React.KeyboardEvent, catCode: string) => void;
	onDropdownSelection: (catCode: string, categories: Categories) => void;
	optionRefs: React.RefObject<HTMLButtonElement>[];
	selectedOptionIdx: number;
}

export const SemanticDropdown: FC<SemanticDropdownProps> = (props) => {
	const {
		categories,
		handleKeyDown,
		onDropdownSelection,
		optionRefs,
		selectedOptionIdx,
	} = props;
	return (
		<div aria-label="listbox" className={Styles.dropdown}>
			{categories.map((category, index) => (
				<button
					aria-pressed="false"
					className={
						selectedOptionIdx === index
							? `${Styles.option} ${Styles.selected}`
							: Styles.option
					}
					data-option-index={index}
					key={category.code}
					onClick={() => onDropdownSelection(category.code, categories)}
					onKeyDown={(e) => handleKeyDown(e, category.code)}
					ref={optionRefs[index]}
					type="button"
					tabIndex={0}
				>
					<div className={SelectOptionStyles.optionIcon}>
						<Icon
							name={`category-${category.code.toLowerCase()}` as IconName}
							size="md"
						/>
					</div>
					<div className={SelectOptionStyles.optionLabel}>{category.name}</div>
				</button>
			))}
		</div>
	);
};
