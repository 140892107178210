import { H4 } from '@xo-union/tk-ui-typography';
import React, { VFC } from 'react';
import { connect } from 'react-redux';
import Styles from './styles.scss';

const { deiSupplementalHeader, supplementalHeader } = Styles;

interface DeiFilter {
	id: string;
	name: string;
}

export interface SupplementalHeaderProps {
	categoryName: string;
	deiFilters: DeiFilter[];
}

export const SupplementalHeader: VFC<SupplementalHeaderProps> = (props) => {
	const { categoryName, deiFilters } = props;

	if (deiFilters.length === 0) {
		return (
			<h5 className={supplementalHeader}>
				Want to see more? Couples in your area recommend these top vendors.
			</h5>
		);
	}

	return (
		<H4 className={deiSupplementalHeader}>
			{deiFilters.length === 1
				? `Looking For More Options? Check Out These ${deiFilters[0].name} ${categoryName}`
				: `Looking For More Options? Check Out These ${categoryName} That Support Diversity`}
		</H4>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	categoryName: state.category.name,
	deiFilters: state.search.filterPills.deiFilters,
});

export default connect(mapStateToProps)(SupplementalHeader);
