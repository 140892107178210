import ExitEarlyAlert from '@components/auto-account-creation/exitEarlyAlert/ExitEarlyAlert';
import Breadcrumbs from '@components/shared/Breadcrumbs';
import { useAppSelector } from '@redux/hooks';
import { Column, Row } from '@xo-union/tk-component-grid';
import React, { FC, useState } from 'react';
import SpotlightAd from '../../SearchResults/components/SpotlightAd';
import { SemanticSearch } from './SemanticSearch';
import { SemanticTitle } from './SemanticTitle';
import Styles from './styles.scss';

export interface SemanticTopBarProps {
	categoryCode: Category.CategoryCode;
	isBOW: boolean;
	location: Redux.Location;
	marketCode: string;
	shouldShowSpotlight: boolean;
}

export const SemanticTopBar: FC<SemanticTopBarProps> = (props) => {
	const {
		categoryCode,
		isBOW,
		location: { city, stateCode },
		marketCode,
		shouldShowSpotlight,
	} = props;
	const [fieldValue, setFieldValue] = useState('');
	const viewport = useAppSelector((state) => state.viewport);
	const lessThanSmall = viewport.lessThan.small;

	return (
		<>
			{shouldShowSpotlight && (
				<Row>
					<Column xs="12">
						<SpotlightAd
							marketCode={marketCode}
							categoryCode={categoryCode}
							isBOW={isBOW}
							className={Styles.adBanner}
						/>
					</Column>
				</Row>
			)}
			{!lessThanSmall && (
				<Row className={Styles.semanticRow}>
					<Column className={Styles.semanticColumnTitle}>
						<Breadcrumbs
							categoryCode={categoryCode}
							city={city}
							stateCode={stateCode}
							isHeader
						/>
						<SemanticTitle
							lessThanSmall={lessThanSmall}
							setFieldValue={setFieldValue}
						/>
					</Column>
					<Column className={Styles.semanticColumnSearch}>
						<SemanticSearch
							fieldValue={fieldValue}
							setFieldValue={setFieldValue}
						/>
					</Column>
				</Row>
			)}
			{lessThanSmall && (
				<>
					<Row className={Styles.semanticRow}>
						<Column xs="12">
							<SemanticSearch
								fieldValue={fieldValue}
								setFieldValue={setFieldValue}
							/>
						</Column>
					</Row>
					<Row>
						<Column xs="12">
							<SemanticTitle
								lessThanSmall={lessThanSmall}
								setFieldValue={setFieldValue}
							/>
						</Column>
					</Row>
				</>
			)}
			<ExitEarlyAlert />
		</>
	);
};
