import { SemanticSearchResponse } from '@api/semanticSearch/types';

export const mockResponse: SemanticSearchResponse = {
	storefronts: [
		{
			id: 'c218faa2-a5d8-48d5-a56d-b0c65ef0ccad',
			similarity_score: 0.47428074,
			match_score: 0.9796929079254367,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: '402680f0-7c9a-4b2d-93a5-c058110cd690',
			similarity_score: 0.42723522,
			match_score: 0.9555836451312946,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: '4f22f1de-3844-4c7a-a7dc-e5f37209a491',
			similarity_score: 0.40913916,
			match_score: 0.9396878851401398,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: '389dc1a4-2596-411c-9b8d-47f2d8348b23',
			similarity_score: 0.39648628,
			match_score: 0.9269248951029098,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: '8c9bc72b-84db-4fa3-9e87-568978d8c88b',
			similarity_score: 0.39188448,
			match_score: 0.9221150849729409,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: '9f7f19c6-0072-41fe-adf1-fd6f3a924045',
			similarity_score: 0.38730413,
			match_score: 0.9173076902505045,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: '5cd91201-9c23-4ba5-830f-a5de00cd1e59',
			similarity_score: 0.3822961,
			match_score: 0.9120819619857925,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: '93b84176-32e1-484e-9672-e8eaf9869da5',
			similarity_score: 0.3771872,
			match_score: 0.9068419298930901,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: '7a79b0e1-4afb-4309-867b-a39d00c6276f',
			similarity_score: 0.37373123,
			match_score: 0.90337823279792,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: 'fdc28fc2-c2c0-46a9-9e05-d20fbf126401',
			similarity_score: 0.36994252,
			match_score: 0.8996783039611644,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: 'ad4ebc92-8804-4d12-8b15-a6a400db08d4',
			similarity_score: 0.3689562,
			match_score: 0.8987340756472634,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: 'beded507-c1b9-4dcb-a8a0-b493ccdbbc1e',
			similarity_score: 0.36278254,
			match_score: 0.893026879702846,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: '0b09e149-01f6-4742-a37d-9edc010a3cb4',
			similarity_score: 0.36247426,
			match_score: 0.8927517398217839,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: 'c5d36ced-6651-4820-94cc-aba97550a550',
			similarity_score: 0.36189175,
			match_score: 0.8922345540166947,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: '4ea490ba-b274-4b52-b7aa-d168351d1d71',
			similarity_score: 0.3613339,
			match_score: 0.8917426110126161,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: 'e4ce6348-876a-49b0-8c8b-408eeefd762f',
			similarity_score: 0.3552647,
			match_score: 0.8866111576726483,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: 'd1bdd1ff-5521-41bd-b4db-46d29a84abe5',
			similarity_score: 0.3545999,
			match_score: 0.8860745013855189,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: 'fc5f16ff-193b-454f-b37e-c480acbccd83',
			similarity_score: 0.35234845,
			match_score: 0.8842955469995815,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: '17a78453-d2b0-453b-8f31-a433009d3371',
			similarity_score: 0.34948355,
			match_score: 0.8821190621835602,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
		{
			id: 'b36b220b-8e5c-df11-849b-0014c258f21e',
			similarity_score: 0.34709918,
			match_score: 0.8803829912100058,
			purchase_status_code: 'PAID',
			has_image: 1,
			claimed_status_code: 'CLAIMED',
		},
	],
	response_id: '800c95f9-0a00-4e46-b416-ab73c706743f',
};
